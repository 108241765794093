import { defineAsyncComponent } from "vue";

export const HEADER_CLASS_ONSCROLL = "shadow backdrop-blur";
export const BREAKPOINTS = {
  lsm: "640px",

  smd: "768px",

  sm: "878px",
  // => @media (min-width: 878px) { ... }

  md: "1024px",
  // => @media (min-width: 968px) { ... }

  lg: "1240px",
  // => @media (min-width: 1024px) { ... }

  xl: "1280px",
  // => @media (min-width: 1280px) { ... }

  "2xl": "1536px",
  // => @media (min-width: 1536px) { ... }
};

export const MIDDLEWARES = {
  PROTECTED: "protected",
};

export const VALIDATION_ERRORS = {
  REQUIRED_EMAIL: "Email address is required",
  REQUIRED_PHONE: "Phone number is required",
  REQUIRED_PASSWORD: "Password is required",
  VALID_EMAIL: "Please enter a valid email address",
  VALID_PASSWORD:
    "Use 8 or more characters with a mix of letters, numbers & symbols",
  PASSWORD_LENGTH_ERROR: "Password should be at least 8 characters long",
  INVALID_MIN_CARD_AMOUNT: "minimum card value to ",
  INVALID_MAX_CARD_AMOUNT: "maximium card value to ",
  REFERRAL_CODE_LENGTH_ERROR: "Referral code should be 6 characters long",
  TERMS_AND_CONDITIONS: "Please accept the terms and conditions",
};

export enum CHANNELS {
  EMAIL = "email",
  PHONE = "phone",
}

export const TOKEN_COUNTDOWN_TIME = 1000; //format in milliseconds

export const VALIDATION_TOKEN_LENGTH = 6;

export const QUICKLINKS = {
  CRYPTO: {
    DEPOSIT: {
      title: "Deposit",
      sub_title: "Deposit",
      support_key: "Deposit",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconDepositTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconDeposit.vue")
      ),
      name: "coin deposit",
      description: "",
      "bg-class": "",
    },
    WITHDRAW: {
      title: "Withdraw",
      sub_title: "Withdraw",
      support_key: "Withdrawal",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdrawTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdrawNew.vue")
      ),
      name: "coin withdraw",
      description: "",
      "bg-class": "",
    },
    TRADE: {
      title: "Trade",
      sub_title: "Trade",
      support_key: "swap",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconTradeCryptoTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconTrade.vue")
      ),
      name: "coin trade_crypto",
      description: "",
      "bg-class": "",
    },
  },
  FIAT: {
    DEPOSIT: {
      title: "Deposit Money",
      sub_title: "Deposit",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconDepositTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconDeposit.vue")
      ),
      name: "fiat deposit",
      description: "fiat deposit",
      "bg-class": "",
    },
    WITHDRAW: {
      title: "Withdraw Money",
      sub_title: "Withdraw",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdrawTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconWithdraw.vue")
      ),
      name: "fiat withdraw",
      description: "",
      "bg-class": "",
    },
    TRADE: {
      title: "Trade",
      sub_title: "Trade",
      two_tone_icon: defineAsyncComponent(
        () => import("@/components/icons/IconTradeCryptoTwoTone.vue")
      ),
      icon: defineAsyncComponent(
        () => import("@/components/icons/IconTradeCrypto.vue")
      ),
      name: "fiat trade_crypto",
      description: "",
      "bg-class": "",
    },
  },
  GIFTCARD: {
    title: "Sell Gift card",
    sub_title: "Gift Card",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconGiftcardTwoTone.vue")
    ),
    icon: "",
    name: "giftcard",
    description: "Buy gift cards at the best rates.",
    "bg-class": "",
  },
  AIRTIME: {
    title: "Airtime",
    sub_title: "Airtime",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconAirtimeTwoTone.vue")
    ),
    icon: "",
    name: "airtime",
    description: "Buy airtime straight from your wallet.",
    "bg-class": "bg-primary-1",
  },
  ELECTRIC_BILL: {
    title: "Light Bill",
    sub_title: "Electric Bill",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconEletricBillTwoTone.vue")
    ),
    icon: "",
    name: "electric_bill",
    description: "Settle your utility bill with ease.",
    "bg-class": "bg-black",
  },
  VIRTUAL_CARD: {
    title: "Virtual Card",
    sub_title: "Virtual Card",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconVirtualCardTwoTone.vue")
    ),
    icon: "",
    name: "virtual_card",
    description: "Virtual Cards for online payments.",
    "bg-class": "",
  },
  DATA_PLAN: {
    title: "Data Plan",
    sub_title: "Data Plan",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconDataTwoTone.vue")
    ),
    icon: "",
    name: "buy_data",
    description: "Easily purchase a data subscription.",
    "bg-class": "bg-secondary-main",
  },
  BILLS: {
    title: "Bills",
    sub_title: "Bills",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconBills.vue")
    ),
    icon: "",
    name: "bills",
    description: "Conveniently pay bills.",
    "bg-class": "",
  },
  TRANSFER: {
    title: "Transfer",
    sub_title: "Transfer",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconPaymentsTwoTone.vue")
    ),
    icon: "",
    name: "transfer",
    description: "Transfer money to other Apex users.",
    "bg-class": "bg-teal-mask",
  },
  CABLE_TV: {
    title: "TV Subscription",
    sub_title: "TV Subscription",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconMonitor.vue")
    ),
    icon: "",
    name: "tv_subscription",
    description: "Subscribe to Your favourite TV shows.",
    "bg-class": "bg-accent-blue-200",
  },
  BET_TOP_UP: {
    title: "Bet Top Up",
    sub_title: "Bet Top-Up",
    two_tone_icon: defineAsyncComponent(
      () => import("@/components/icons/IconBetSlip.vue")
    ),
    icon: "",
    name: "bet_top_up",
    description: "Fund your betting accounts",
    "bg-class": "bg-primary-purple",
  },
};

export const ROUTES = {
  LOGIN: {
    name: "Login",
    path: "/login",
    displayName: "",
  },
  MAINTENANCE: {
    name: "Maintenance",
    path: "/maintenance-mode",
  },
  CREATE_ACCOUNT: {
    name: "Create Account",
    path: "/create-account",
    displayName: "",
    TELL_US_MORE: {
      path: "/create-account/tell-us-more",
      name: "",
    },
  },
  RESET_PASSWORD: {
    name: "Reset Password",
    path: "/reset-password",
    displayName: "",
  },
  INDEX: {
    name: "Index",
    path: "/",
    displayName: "Overview",
  },
  WITHDRAW: {
    name: "Withdraw",
    path: "/withdraw",
    displayName: "Withdraw",
  },
  DEPOSIT: {
    name: "Deposit",
    path: "/deposit",
    displayName: "Deposit",
  },
  TRADE: {
    name: "Trade",
    path: "/trade",
    displayName: "Trade",
    DEPOSIT: {
      name: "Deposit",
      path: "/trade/deposit/:category",
      displayName: "Deposit",
    },
    WITHDRAW: {
      name: "Withdraw",
      path: "/trade/withdraw/:category",
      displayName: "Withdraw",
    },
    SWAP: {
      name: "Swap",
      path: "/trade/swap",
      displayName: "Swap",
    },
  },
  GIFT_CARD_SELL: {
    name: "Gift Card",
    path: "/giftcards",
    displayName: "Giftcards",
    OFFER: {
      path: "/giftcards/sell/:id",
      name: "Gift Card Sell Offer",
    },
  },
  GIFT_CARD_TRADES: {
    name: "Gift Card Trades",
    path: "/giftcards/trades",
    displayName: "Giftcards",
  },
  GIFT_CARD_BUY: {
    name: "Gift Card Buy",
    path: "/giftcards/buy",
    displayName: "Giftcards",
    OFFER: {
      path: "/giftcards/buy/:name",
      name: "Gift Card Buy Offer",
    },
  },
  GIFT_CARD_TRADE_CHAT: {
    name: "Gift Card Trade Chat",
    path: "/giftcards/trades/:id",
    displayName: "Gift card trade",
  },
  BILLS: {
    name: "Bills",
    path: "/bills",
    displayName: "Bills",
    AIRTIME: {
      name: "Airtime",
      path: "/bills/airtime",
      displayName: "Buy Airtime",
    },
    ELECTRIC_BILL: {
      name: "Electric Bill",
      path: "/bills/electric-bill",
      displayName: "Electric Bill",
    },
    DATA_PLAN: {
      name: "Data Plan",
      path: "/bills/data-plan",
      displayName: "Data Plan",
    },
    CABLE_TV: {
      name: "Cable TV",
      path: "/bills/cable-tv",
      displayName: "Cable TV",
    },
  },
  BET_TOP_UP: {
    name: "Bet Top Up",
    path: "/bet-top-up",
    displayName: "Bet Top Up",
  },
  TRANSACTIONS: {
    name: "Transactions",
    path: "/transactions",
    displayName: "Transactions",
    DETAILS: {
      name: "Transaction Details",
      path: "/transactions/:category/:id",
      displayName: "Transactions",
    },
    SORT_BY: {
      name: "Transactions",
      path: "/transactions/sort",
      displayName: "Transactions",
    },
  },
  PROFILE: {
    name: "Profile",
    path: "/profile",
    displayName: "Account Details",
    INFORMATION: {
      name: "Personal Information",
      path: "/profile/Information",
      displayName: "Edit Profile",
    },
    UPGRADE: {
      name: "Profile Upgrade",
      path: "/profile/upgrade",
      displayName: "Profile Upgrade",
    },
    SECURITY: {
      name: "Login and Security",
      path: "/profile/security",
      displayName: "Login and Security",
    },
    BUSINESS: {
      name: "Business API",
      path: "/profile/business",
      displayName: "Business API",
    },
    EMAIL_UPDATE: {
      name: "Update Email",
      path: "/profile/update/:identifier_type",
      displayName: "Update Email",
    },
    USERNAME_UPDATE: {
      name: "Update username",
      path: "/profile/update/username",
      displayName: "Update Username",
    },
    ACCOUNT: {
      name: "Account",
      path: "/profile/account",
      displayName: "Account Settings",
    },
  },
  WALLET: {
    name: "Wallet",
    path: "/wallets",
    displayName: "Wallets",
    ID: {
      name: "Wallet:ASSET",
      path: "/wallets/overview/:category/:asset",
      displayName: "Wallet",
      DETAILS: {
        name: "Single Transaction Details",
        path: "transactions/:id",
        displayName: "Transactions",
      },
    },
  },
  CARDS: {
    name: "Cards",
    path: "/cards",
    displayName: "Cards",
    CREATE: {
      name: "Create Card",
      path: "/cards/create",
      displayName: "Create Card",
    },
    ALL: {
      name: "All Cards",
      path: "/cards/all",
      displayName: "All Cards",
    },
    FUND: {
      name: "Fund Card",
      path: "/cards/fund/:id",
      displayName: "Fund Card",
    },
    TRANSACTION: {
      name: "Card Transaction",
      path: "/cards/:cardId/transaction/:transactionId",
      displayName: "Card Transaction",
    },
    WITHDRAW: {
      name: "Card Withdrawal",
      path: "/cards/withdraw/:id",
      displayName: "Card Withdrawal",
    },
    DETAILS: {
      name: "Card Details",
      path: "/cards/:id",
      displayName: "Card Details",
    },
    EDIT_LABEL: {
      name: "Edit Card Label",
      path: "/cards/edit-label/:id",
      displayName: "Edit Card Label",
    },
    MORE: {
      name: "More",
      path: "/cards/more/:id",
      displayName: "More",
    },
  },
  REFERRALS: {
    name: "Referrals",
    path: "/referrals",
    displayName: "Referrals",
  },
};

export enum EVENTBUS {
  SHOW_QUICKLINK_DIALOG = "SHOW_QUICKLINK_DIALOG",
  LOGOUT = "LOGOUT",
  SHOW_QUICKLINK_VALIDATION_ERROR = "SHOW_QUICKLINK_VALIDATION_ERROR",
  SHOW_SERVICE_ERROR = "SHOW_SERVICE_ERROR",
  SHOW_TRANSACTION_DETAILS_DIALOG = "SHOW_TRANSACTION_DETAILS_DIALOG",
  SHOW_TRADE_DETAILS_DIALOG = "SHOW_TRADE_DETAILS_DIALOG",
  REFRESH_WALLETS = "REFRESH_WALLETS",
  LAUNCH_CAMPAIGNS = "LAUNCH_CAMPAIGNS",
}

export const countryInputGlobalOptions = {
  autoDefaultCountry: true,
  defaultCountry: "NG",
  preferredCountries: ["NG", "GH", "KE", "RW"],
  showSearchBox: true,
  dropdownOptions: {
    showDialCodeInSelection: true,
    showSearchBox: true,
  },
};

export const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  },
};

export const TRANSACTION_TITLE = "Reference";

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toCamelCase(str: string): any {
  return str
    .split("-")
    .map((i, j) => (j == 0 ? i : capitalizeFirstLetter(i)))
    .join("");
}

export function objectsToCamelCaseObjects(obj: any) {
  let limits = {};
  for (const property in obj) {
    if (typeof obj[property] === "object") {
      Object.defineProperty(limits, toCamelCase(property), {
        value: objectsToCamelCaseObjects(obj[property]),
        writable: true,
      });
    } else {
      Object.defineProperty(limits, toCamelCase(property), {
        value: obj[property],
        writable: true,
      });
    }
  }
  return limits;
}

export const AXIOS_ERROR_NAME = "AxiosError";

export const RECAPTCHA_VALIDATION_TOKEN_KEY = "request_validation_token";

export const RECAPTCHA_PASSWORD_RESET_TOKEN_KEY =
  "request_password_reset_token";

export const ALLOWED_CURRENCIES_FOR_WITHDRAWAL = ["ghs", "ngn", "zmw"];
export const ALLOWED_CURRENCIES_FOR_DEPOSIT = ["ngn"];

export enum WITHDRAWAL_TYPES {
  MOBILE_MONEY = "mobile_money",
  BANK_TRANSFER = "bank_transfer",
}

export const DEFAULT_CURRENCY = "ngn";
